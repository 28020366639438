import { render, staticRenderFns } from "./MatrixImageCarousel.vue?vue&type=template&id=0b21bbf5&scoped=true"
import script from "./MatrixImageCarousel.vue?vue&type=script&lang=js"
export * from "./MatrixImageCarousel.vue?vue&type=script&lang=js"
import style0 from "./MatrixImageCarousel.vue?vue&type=style&index=0&id=0b21bbf5&prod&lang=scss"
import style1 from "./MatrixImageCarousel.vue?vue&type=style&index=1&id=0b21bbf5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b21bbf5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,AtomIconArrowLeft: require('/home/userapp/components/Atom/Icon/IconArrowLeft.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default})
