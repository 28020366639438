
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    source: { type: String, required: true },
    quote: { type: String, required: true },
    role: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
  },
})
