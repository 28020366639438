
import get from 'lodash/get'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    enableLastItemRounded: { type: Boolean, required: false, default: false },
  },
  setup() {
    return { get }
  },
})
