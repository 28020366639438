
import { defineComponent, onMounted, onUnmounted, ref, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    externalForm: { type: String, required: true },
  },
  setup(props) {
    const { $config } = useContext()
    const height = ref('411px')
    const iframe = ref(null)
    const url = `${$config.wwwSiteUrl}/externalForm/${props.externalForm}.html`

    const onResize = () => {
      height.value = iframe.value.contentDocument.body.scrollHeight
    }

    const onLoad = () => {
      // Set initial size now that the iFrame has loaded
      onResize()

      // Listen for resize events
      const resizeObserver = new ResizeObserver(onResize)
      resizeObserver.observe(iframe.value.contentDocument.body)
    }

    // Make sure that the event listener is also removed, otherwise they will accumulate
    onMounted(() => iframe.value.addEventListener('load', onLoad))
    onUnmounted(() => iframe.value.removeEventListener('load', onLoad))

    return { height, iframe, url }
  },
})
