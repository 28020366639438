
import get from 'lodash/get'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { Glide, GlideSlide } from 'vue-glide-js'
import { getMediaImage } from '~/assets/media'
import { setupTranslationPrefixer } from '~/assets/util'

//
// For vue-glide documentation, see https://antonreshetov.github.io/vue-glide/props/
//

export default defineComponent({
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {
    entries: { type: Array, required: true },
    breakpoints: {
      type: Object,
      required: false,
      default: () => {
        return {
          800: { peek: { before: 0, after: 0 } },
          999999: { peek: { before: 100, after: 100 } },
        }
      },
    },
    perView: { type: Number, required: false, default: 1 },
  },
  setup(props) {
    const slider = ref()
    const activeSlide = ref(0)
    const amendedEntries = props.entries.map((entry) => ({ ...entry, image: getMediaImage(entry.image) }))
    return { activeSlide, amendedEntries, get, slider, ...setupTranslationPrefixer('carousel') }
  },
})
