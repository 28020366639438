
import pick from 'lodash/pick'
import { defineComponent, onMounted, onUnmounted, ref, useRoute } from '@nuxtjs/composition-api'

function getAmendedUrl(url) {
  if (!url) {
    return undefined
  }

  const route = useRoute()
  return [url, new URLSearchParams(pick(route.value.query, ['freq', 'bedrag', 'bedragOpen'])).toString()].filter((value) => value).join(url.includes('?') ? '&' : '?')
}

function regexpGet(value, expression) {
  return (value || '').match(expression)?.[1]
}

export default defineComponent({
  props: {
    url: { type: String, required: false, default: undefined },
  },
  setup(props) {
    const route = useRoute()
    const iframeOrigin = regexpGet(props.url, /^(https?:\/\/[^/]+)/i)
    const iframe = ref(null)
    const height = ref('411px')
    const amendedUrl = getAmendedUrl(props.url)

    const listener = (message) => {
      if (message.origin !== iframeOrigin) {
        // Ignore messages from unexpected origins
        return
      }

      if (message.data?.type === 'height') {
        // eslint-disable-next-line no-console
        console.debug(`Updating iframe height to ${message.data?.height}`)
        height.value = `${message.data?.height}px`
      } else if (message.data?.type === 'ready') {
        const prefill = route.value.query.prefillen
        if (prefill) {
          // eslint-disable-next-line no-console
          console.debug('Sending prefill information to iframe')
          // Send message to iframe with prefillen value
          iframe.value.contentWindow.postMessage({ type: 'updateIframe', prefillen: prefill }, iframeOrigin)
        }
      } else {
        // eslint-disable-next-line no-console
        console.debug(`Ignoring unknown message from iframe`)
      }
    }

    // Make sure that the event listener is also removed, otherwise they will accumulate
    onMounted(() => window.addEventListener('message', listener))
    onUnmounted(() => window.removeEventListener('message', listener))

    return { height, iframe, amendedUrl }
  },
})
