import { render, staticRenderFns } from "./Matrix.vue?vue&type=template&id=56f7a978&scoped=true"
import script from "./Matrix.vue?vue&type=script&lang=js"
export * from "./Matrix.vue?vue&type=script&lang=js"
import style0 from "./Matrix.vue?vue&type=style&index=0&id=56f7a978&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f7a978",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeMatrixBanner: require('/home/userapp/components/Molecule/Matrix/MatrixBanner.vue').default,MoleculeMatrixDonationIframe: require('/home/userapp/components/Molecule/Matrix/MatrixDonationIframe.vue').default,MoleculeMatrixExternalForm: require('/home/userapp/components/Molecule/Matrix/MatrixExternalForm.vue').default,MoleculeMatrixForm: require('/home/userapp/components/Molecule/Matrix/MatrixForm.vue').default,MoleculeMatrixImage: require('/home/userapp/components/Molecule/Matrix/MatrixImage.vue').default,MoleculeMatrixImageCarousel: require('/home/userapp/components/Molecule/Matrix/MatrixImageCarousel.vue').default,MoleculeMatrixKentaaIframe: require('/home/userapp/components/Molecule/Matrix/MatrixKentaaIframe.vue').default,MoleculeMatrixQuestionAnswers: require('/home/userapp/components/Molecule/Matrix/MatrixQuestionAnswers.vue').default,MoleculeMatrixQuote: require('/home/userapp/components/Molecule/Matrix/MatrixQuote.vue').default,MoleculeMatrixRawText: require('/home/userapp/components/Molecule/Matrix/MatrixRawText.vue').default,MoleculeMatrixText: require('/home/userapp/components/Molecule/Matrix/MatrixText.vue').default})
