
import { defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'

function regexpGet(value, expression) {
  return (value || '').match(expression)?.[1]
}

export default defineComponent({
  props: {
    kentaaWidgetId: { type: String, required: true },
    kentaaWidgetUrl: { type: String, required: true },
  },
  setup(props) {
    const iframeOrigin = regexpGet(props.kentaaWidgetUrl, /^(https?:\/\/[^/]+)/i)
    const iframeUrl = [props.kentaaWidgetUrl, 'locale=nl&hide_about_us=1'].join(props.kentaaWidgetUrl?.includes('?') ? '&' : '?')
    const iframe = ref(null)
    const height = ref('411px')

    const listener = (message) => {
      if (message.origin !== iframeOrigin) {
        // Ignore non-kentaa messages
        return
      }

      // message.data contains a string in the form '_WIDGET_site_18&height=826'
      // Retrieve the height if this is the correct site_18
      const kentaaWidgetId = regexpGet(message.data, /_WIDGET_([a-z_0-9]+)/i)
      const newHeight = regexpGet(message.data, /height=([0-9]+)/i)

      if (props.kentaaWidgetId === kentaaWidgetId && newHeight) {
        // eslint-disable-next-line no-console
        console.debug(`Updating iframe height to ${15 + parseInt(newHeight)}px`)
        // Adding 15px to the height because sometimes the provided height is incorrect and/or there is a hover effect that would otherwise show a scrollbar
        height.value = `${15 + parseInt(newHeight)}px`
      } else {
        // eslint-disable-next-line no-console
        console.debug(`Ignoring unknown message from iframe`)
      }
    }

    // Make sure that the event listener is also removed, otherwise they will accumulate
    onMounted(() => window.addEventListener('message', listener))
    onUnmounted(() => window.removeEventListener('message', listener))

    return { height, iframe, iframeUrl }
  },
})
